import React, { Component } from "react";

import "./style.css";

export default class ExpandedImageWithList extends Component {
  render() {
    let data = this.props.data;
    let list = data.list.map((elm, idx) => {
      return <li key={elm + idx}>{elm}</li>;
    });
    return (
      <div className="enterprise-features-wrap">
        <div className="enterprise-features-img-wrap">
          <img src={data.image} alt="enterprise-img" loading="lazy" />
        </div>
        <div className="enterprise-features-details">
          <h2>{data.heading}</h2>
          <ul>{list}</ul>
        </div>
      </div>
    );
  }
}
