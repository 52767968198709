import React, { Component } from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import EazyHeader from "../../components/header-eazy";
import { EnterpriseDemo } from "../../components/hero-eazy";
import ExpandedImageWithList from "../../components/ExpandedImageWithList";
import animateScrollTo from "animated-scroll-to";
import Layout from "../../components/layout";
import BannerWithText from "../../components/BannerWithText";
import WithProviders from "../../components/WithProviders";

import "./style.css";

import bannerImg from "../images/banner-enterprise.jpg";
import builtIcon from "../images/built-icon.svg";
import multistore from "../images/multistore-enterprise.svg";
import link from "../images/link.svg";
import modular from "../images/modular-enterprise.svg";
import cloudBased from "../images/cloud-based.svg";
import dispatch from "../images/dispatch_img.png";
import easyManage from "../images/easy-manage.png";
import notifications from "../images/notifications.png";
import oms from "../images/oms.png";
import efficientProcess from "../images/efficient-process.png";
import group from "../images/group-31.png";
import efficientPayment from "../images/efficient-payment.png";
import crm from "../images/crm.png";

const FeaturesData = [
  {
    heading: "Wow your customers with a superlative user experience",
    image: dispatch,
    list: [
      <span>Fast loading, quick navigation and intuitive&nbsp;search</span>,
      <span>
        Personalized search results and Notify-me feature for
        out-of-stock&nbsp;products
      </span>,
      <span>
        Customers can select substitutes for their preferred&nbsp;products
      </span>,
      <span>
        Customers can reschedule orders and book returns on their&nbsp;own
      </span>,
    ],
  },
  {
    heading:
      "Easily manage your catalogue using our Catalogue Management System",
    image: easyManage,
    list: [
      <span>
        Capability to create complex product offers such as BOGO, product
        bundles and X% free with&nbsp;Y
      </span>,
      <span>Easy updation of catalogue using Catalogue&nbsp;App</span>,
      <span>
        Correction of inventory in a prioritized sequence using
        Inventory-checker&nbsp;App
      </span>,
      <span>
        Capturing and processing of photos in a prioritized sequence using
        Photo&nbsp;App
      </span>,
    ],
  },
  {
    heading:
      "Run targeted marketing campaigns using our Campaigns Management Module",
    image: notifications,
    list: [
      <span>Customer segmentation based on multiple&nbsp;parameters</span>,
      <span>Offers engine to create complex offers and&nbsp;coupons</span>,
      <span>
        Communication engine to send rich content via App-notifications
        and&nbsp;E-mails
      </span>,
      <span>
        Insightful analytics for each aspect of marketing&nbsp;campaigns
      </span>,
    ],
  },
  {
    heading: "Flawlessly manage orders using our Order Management System (OMS)",
    image: oms,
    list: [
      <span>OMS console to view orders with their complete&nbsp;details</span>,
      <span>Pre-defined order statuses for each stage of&nbsp;order</span>,
      <span>Instant payment and refunds updates against each&nbsp;order</span>,
      <span>
        Detailed logs for each activity to trace back any&nbsp;errors
      </span>,
    ],
  },
  {
    heading: "Efficiently process orders with our Operations Management System",
    image: efficientProcess,
    list: [
      <span>
        Intelligent order sequence generator for optimal&nbsp;picking
      </span>,
      <span>Picking App that enables quick and error-free&nbsp;picking</span>,
      <span>
        System enables on-the-go reporting and correction of&nbsp;catalogue
      </span>,
      <span>
        Staff and asset management module to manage attendance
        and&nbsp;performance.
      </span>,
    ],
  },
  {
    heading: "Optimally deliver orders with our Delivery Management System",
    image: group,
    list: [
      <span>
        Auto-routing that optimizes delivery sequence based on&nbsp;live-traffic
      </span>,
      <span>
        Delivery App to reach delivery address, collect cash and
        accept&nbsp;returns
      </span>,
      <span>
        Monitoring module for tracking trips and raise alarms in case
        of&nbsp;delays
      </span>,
      <span>
        Return module to capture product returns and process&nbsp;refunds
      </span>,
    ],
  },
  {
    heading: "Reliably collect payments using our payment gateway solution",
    image: efficientPayment,
    list: [
      <span>SSL Certificate for secure&nbsp;transactions</span>,
      <span>Failover support between multiple payment&nbsp;gateways</span>,
      <span>
        Advanced algorithms to dynamically decide the optimal
        payment&nbsp;gateway
      </span>,
      <span>Robust reporting and&nbsp;reconciliation</span>,
    ],
  },
  {
    heading: "Delight your customers with our  (CRM) Module",
    image: crm,
    list: [
      <span>
        Unified interface to find all customer details including past e-mails
        and call&nbsp;recordings
      </span>,
      <span>
        Console to edit orders, change delivery slot, cancel orders, initiate
        refunds,&nbsp;etc.
      </span>,
      <span>
        Capability to send information via Email, SMS, and&nbsp;App-notification
      </span>,
      <span>Detailed monitoring of performance of each&nbsp;agent</span>,
    ],
  },
];
let options = {
  // duration of the scroll per 1000px, default 500
  speed: 500,

  // minimum duration of the scroll
  minDuration: 250,

  // maximum duration of the scroll
  maxDuration: 1500,

  // DOM element to scroll, default window
  // Pass a reference to a DOM object
  // Example: document.querySelector('#element-to-scroll'),

  // Additional offset value that gets added to the desiredOffset.  This is
  // useful when passing a DOM object as the desiredOffset and wanting to adjust
  // for an fixed nav or to add some padding.
  offset: 0,

  // should animated scroll be canceled on user scroll/keypress
  // if set to "false" user input will be disabled until animated scroll is complete
  // (when set to false, "passive" will be also set to "false" to prevent Chrome errors)
  cancelOnUserAction: true,

  // Set passive event Listeners to be true by default. Stops Chrome from complaining.
  passive: true,

  // Scroll horizontally rather than vertically (which is the default)
  horizontal: true,

  // function that will be executed when the scroll animation is finished
  onComplete: function () {},
};
class Enterprise extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }
  onClick = (e) => {
    this.setState({ showModal: true });
  };
  onClose = (e) => {
    this.setState({ showModal: false });
  };
  moveLeft = (e) => {
    let scrollWidth = document.querySelector(".brief-features-elm").clientWidth;
    options.element = document.querySelector(".brief-features");
    options.offset = -6;
    animateScrollTo(
      document.querySelector(".brief-features").scrollLeft - scrollWidth,
      options,
    );
  };
  moveRight = (e) => {
    let scrollWidth = document.querySelector(".brief-features-elm").clientWidth;
    options.element = document.querySelector(".brief-features");
    options.offset = 6;
    animateScrollTo(
      document.querySelector(".brief-features").scrollLeft + scrollWidth,
      options,
    );
  };
  render() {
    const { language, updateLanguage, direction } = this.props;
    return (
      <Layout showFooterForm language={language} location={this.props.location}>
        <div className="eazy-page enterprise-page">
          <Helmet title="SmartStore - Enterprise" />
          <Header
            eazy
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <EazyHeader links={[]} enterprise />
          <BannerWithText
            data={{
              src: bannerImg,
              title: "SmartStore Enterprise",
              subtitle: "The ultimate Omni-channel platform for enterprises",
              action: "GET A DEMO",
              button: true,
              textAlign: "left",
              callback: this.onClick,
            }}
          />
          <div className="brief-features-wrap">
            <section className="brief-features">
              <div className="brief-features-elm">
                <img src={builtIcon} alt="built-icon" loading="lazy" />
                <p>Built for profitability</p>
              </div>
              <div className="brief-features-elm">
                <img src={multistore} alt="built-icon" />
                <p>Multi-store operations</p>
              </div>
              <div className="brief-features-elm">
                <img src={link} alt="link" />
                <p>Minimal integration needs</p>
              </div>
              <div className="brief-features-elm">
                <img src={modular} alt="modular" />
                <p>Modular and scalable</p>
              </div>
              <div className="brief-features-elm">
                <img src={cloudBased} alt="cloud-based" />
                <p>Cloud based cutting-edge technology</p>
              </div>
            </section>
            <div
              aria-label="left arrow"
              className="arrow"
              onClick={this.moveLeft}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            />
            <div
              aria-label="right arrow"
              className="arrow"
              onClick={this.moveRight}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            />
          </div>
          <section className="enterprise-features">
            <div className="header tc">
              <h2>Key features</h2>
              <p className="muted">
                The most comprehensive omni-channel platform to run your retail
                business
              </p>
              <hr />
            </div>
            <div className="features-list">
              {FeaturesData.map((elm, idx) => {
                return (
                  <ExpandedImageWithList
                    key={elm.heading + idx}
                    data={{
                      heading: elm.heading,
                      image: elm.image,
                      list: elm.list,
                    }}
                  />
                );
              })}
            </div>
          </section>
          <section className="enterprise-omni-channel">
            <div className="header tc">
              <h2>Omni-channel</h2>
              <p className="muted">
                Use these additional services to grow your business
              </p>
              <hr />
            </div>
          </section>
          <EnterpriseDemo
            showModal={this.state.showModal}
            onClose={this.onClose}
          />
        </div>
      </Layout>
    );
  }
}

export default WithProviders(Enterprise);
