import React, { Component } from 'react'
import Text from '../Text'
import Button from '../Button'
import Image from '../Image'

import './style.css'

import banner from './group-25.svg'
import bannerWithButton from './group-26.svg'

class BannerWithText extends Component {
  render () {
    const props = this.props.data
    
    if(!Object.keys(props).length) {
      return <Image src={banner} alt='default-banner' />
    }

    const style = {
      backgroundImage: `url(${props.src})`
    }
    let button = ''
    if (props.button) {
      if(Object.keys(props).length === 1) {
        return <Image src={bannerWithButton} alt='default-banner' />
      }
      button = <Button data={{
        value: props.action,
        link: props.link,
        callback: props.callback
      }} /> 
    }

    const comp = <div className='zc-banner-with-text' style={style}>
      <div className={`zc-banner-content-div ${props.textAlign === 'right' ? 'alignRight' : 'alignLeft'}`}>
        <div className='wrap'>
        <Text data={{
          title: props.title,
          fontSizeForTitle: props.fontSizeForTitle,
          textColorForTitle: props.textColorForTitle,
          subtitle: props.subtitle,
          details: props.details,
          textColorForDescription: props.textColorForDescription,
          fontSizeforDescription: props.fontSizeforDescription
        }} />
        { <div className='banner-buttons-container'>{button}</div> }
        </div>
      </div>
  </div>

    return (
        props.link ? <a href={`/${props.link}`}>
          {comp}
    </a> : <div>{comp}</div>
    )
  }
}

export default BannerWithText
